import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadUser } from './user.actions';
import { Injectable } from "@angular/core";
import * as UserActions from './user.actions';
import { KeycloakService } from 'keycloak-angular';
import { mergeMap, map, catchError, of } from 'rxjs';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.loadUser),
    mergeMap(() => this.keycloakService.loadUserProfile().then(user => {
      return UserActions.loadUserSuccess({ user });
    }).catch(error => {
      return UserActions.loadUserFailure({ error });
    }))
  ));

  constructor(
    private actions$: Actions,
    private keycloakService: KeycloakService
  ) {}
}
