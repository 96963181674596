import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';


import {
  loadCompanies,
  loadCompaniesSuccess,
  loadCompaniesFailure,
  loadCompanyUnits,
  loadCompanyUnitsSuccess,
  loadCompanyUnitsFailure,
  changeSelectedCompany,
} from './company-context.actions';
import { CompanyService } from '../../services/companies/company.service';

@Injectable()
export class CompanyContextEffects {  
  // Effect to load companies based on pagination and search term
  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompanies),
      exhaustMap(() =>
        from(this.companyService.getCompanies()).pipe(
          map(res => loadCompaniesSuccess({items: res.content})),
          catchError(error => of(loadCompaniesFailure({ error: error.message })))
        ))
    )
  );

  selectInitialCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCompaniesSuccess),
      map(({ items }) => {
        if (items.length > 0) {
          return changeSelectedCompany({ item: items[0] });
        }
        return loadCompanyUnitsFailure({ error: 'No companies found to select.' });
      })
    )
  );

  loadCompanyUnitsWhenCompanySelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeSelectedCompany),
      exhaustMap(({ item }) =>
        from(this.companyService.getCompanyUnits(item.id, false, 0, 10000, '')).pipe(
          map(page => loadCompanyUnitsSuccess({ page })),
          catchError(error => of(loadCompanyUnitsFailure({ error: error.message })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
  ) { }
}
